import React from 'react';
import Layout from '@components/Layout';

import Development from '@components/Development';

const App = () => {
  return (
    <Layout title="Arkos Digital - Desenvolvimento de produtos digitais personalizados.">
      <Development />
    </Layout>
  );
};

export default App;
